.form-group {
	& > label {
		font-family: $font-family-second;
		color: $secondary;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 0.6rem;
	}
	.form-control,
	.custom-select {
		border-width: 0 0 2px 0;
		border-radius: 0;
		padding-left: 0;
		box-shadow: none !important;
	}
	.form-number {
		border-width: 2px 0;
		text-align: center;
	}
}

.btn {
	font-family: $font-family-second;
	font-size: 0.8rem;
	text-transform: uppercase;
	&-group-toggle {
		.btn {
			padding: 0.4375rem 0.75rem;
		}
	}
}

.custom-file-label {
	white-space: nowrap;
	&::after {
		content: "\f07c" !important;
		font-family: "Line Awesome Free";
		font-weight: 900;
	}
}

.brand {
	img {
		height: 36px;
	}
	img + img {
		margin-left: 1rem;
	}
}

.card {
	&-title {
		margin: 0;
		font-size: 0.8rem;
		letter-spacing: 1px;
		font-weight: 700;
		text-transform: uppercase;
		color: $gray-700;
	}
	&-header {
		position: relative;
		.btn-toggle {
			position: absolute;
			top: 5px;
			right: 0;
			i::before {
				content: "\f068";
			}
			&.collapsed i::before {
				content: "\f7a4";
			}
		}
	}
	&-body {
		&.show,
		&.collapse,
		&.collapsing {
			padding: 0;
			& > div {
				padding: 1.25rem;
			}
		}
	}
}
.card.show + .card.show {
	margin-top: 15px;
}
.navbar {
	&-brand {
		margin-right: 2rem;
	}
	&-nav {
		.nav-item {
			text-transform: uppercase;
			font-size: 0.75rem;
			letter-spacing: 1px;
			padding: 0;
			font-weight: 700;
			line-height: 1.75;
		}
	}
	&-text {
		text-transform: uppercase;
		font-weight: 400;
		font-size: 0.8rem;
	}
}

.table {
	th {
		font-size: 0.7rem;
		text-transform: uppercase;
		color: $gray-700;
	}
	td {
		font-size: 0.8rem;
	}
	&-vmid {
		td {
			vertical-align: middle;
		}
	}
}

.react-datepicker-wrapper {
	width: 100%;
}

.photo-box {
	position: relative;
	& > span {
		background: rgba(0, 0, 0, 0.5);
		color: #fff;
		position: absolute;
		font-size: 0.7rem;
		font-weight: 700;
		letter-spacing: 1px;
		bottom: 0;
		left: 0;
		width: 24px;
		height: 20px;
		line-height: 20px;
		text-align: center;
	}
}

.color-block {
	display: inline-block;
	width: 16px;
	height: 16px;
	border-radius: 2px;
	margin-right: 10px;
	vertical-align: text-top;
	border: 1px solid #f3f3f3;
}

.pagination {
	user-select: none;
	margin-bottom: 0;
	.page-link {
		span,
		i {
			pointer-events: none;
		}
	}
	.input-group {
		&-text,
		.form-control {
			border-radius: 0;
		}
		.form-control {
			width: 60px;
			text-align: right;
			padding-top: 5px;
			border-left: 0;
		}
	}
}

#Session_table {
	.not-send {
		color: #ffc107;
		text-transform: uppercase;
	}

	.muvit-response {
		display: none;
		position: absolute;
		z-index: 100;
		background: #6c757d;
		opacity: .9;
		padding: 10px;
		font-size: 12px;
		color: #ffffff;
		border-radius: 5px;
		font-weight: bold;
		top: -40%;
	}

	i.cl-circle {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		display: inline-block;
	}

	.muvit-status {
		position: relative;
	}

	.sent {

		color: #38e091;
		text-transform: uppercase;

		i.cl-circle {
			background: #38e091;
		}
	}

	.failed {
		cursor: pointer;
		color: #dc3545;
		text-transform: uppercase;

		i.cl-circle {
			background: #dc3545;
		}
	}

	.failed:hover .muvit-response {
		display: block;
	}
}

.content-download-complete-session {
	width: 600px;
}

.input_container input {
	text-transform: none;
}

.dropdown {
	top: 35px;
	width: 100%;
	min-width: 0;
	max-width: 100%;
	max-height: 250px;
	overflow-x: hidden;
	background-color: #fff;
	visibility: hidden;
	z-index: 10;
	box-shadow: 1px 3px 5px #888888;

	&.v {
		visibility: visible;
	}

	ul {
		padding: 0;
		margin: 0;
		.dropdown_item {
			width: 100%;
			max-width: 100%;
			padding: 5px 12px;
			cursor: pointer;
			&:hover {
				background-color: #f1f1f1d7;
			}
			border-bottom: 1px solid #b3b3b36b;
			list-style: none;
		}

		.item_text1 {
			font-size: 14px;
			margin-bottom: 2px;
			margin-top: 2px;
			text-transform: none;
			font-weight: 500;
		}

		.item_text2 {
			font-size: 14px;
			color: #090a3c80;
		}
	}
}


