@media (max-width: 1024px) {
	.navbar {
		padding: 0.5rem 0;
		&-brand {
			padding: 0.5rem 1rem;
			& > img {
				height: 28px;
			}
		}
		&-toggler-icon {
			content: "\f0c9";
			font-family: "Line Awesome Free";
		}
		&-toggler {
			outline: none !important;
			box-shadow: none;
			.navbar-on {
				display: none;
			}
			&.collapsed {
				.navbar-on {
					display: inline-block;
				}
				.navbar-off {
					display: none;
				}
			}
		}
	}
	#navbarNav {
		position: absolute;
		right: 65px;
		z-index: 10;
		left: 0;
		top: 0;
		flex-direction: row;
		justify-content: flex-end;
		& > li {
			line-height: 46px;
			.navbar-text,
			.btn {
				padding: 0 0.5rem !important;
				line-height: 46px;
				& > i {
					font-size: 1.75rem !important;
				}
			}
			.btn {
				padding-top: 5px !important;
			}
		}
	}
	.pagination {
		.input-group {
			.form-control {
				font-size: 14px;
				text-align: center;
			}
		}
	}
	.heading {
		h1 {
			font-size: 0.75rem;
		}
		.buttons {
			top: 9px;
		}
	}
}
