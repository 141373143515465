#Header {
	user-select: none;
	&.default {
		background: $primary;
		.navbar {
			padding-top: 0;
			padding-bottom: 0;
			&-nav {
				.nav-link {
					color: $gray-500;
					padding: 1.3rem 2rem;
					&.nav-fit {
						padding: 1rem 0;
						opacity: 0.75;
						&:hover {
							background: transparent !important;
							opacity: 1;
						}
					}
				}
				.active > .nav-link,
				.nav-link:hover {
					color: $gray-100;
				}
				.active > .nav-link {
					background: lighten($primary, 3%);
				}
				.nav-link:hover {
					background: lighten($primary, 5%);
				}
			}
			&-text {
				color: $gray-500;
				padding: 1.3rem 1rem;
				& > span {
					color: $white;
				}
			}
		}
		+ section {
			& > .heading {
				background: lighten($primary, 3%);
				color: $white;
			}
		}
	}
}
#Alert {
	position: fixed;
	z-index: 899;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	padding: 0.5rem 0;
	.alert {
		position: relative;
		display: inline-block;
		padding: 10px 45px 10px 15px;
		font-size: 0.9rem;
		background: #fff;
		.btn-close {
			position: absolute;
			top: 50%;
			right: 10px;
			padding: 0;
			margin-top: -13px;
		}
		&-errors {
			border: 2px solid #ed4f32;
		}
		&-done {
			border: 2px solid #15cd72;
		}
		&-notify {
			border: 2px solid #fad01a;
		}
	}
}
#Loading {
	user-select: none;
	position: fixed;
	z-index: 900;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0) !important;
	.text-loading {
		font-size: 0.6rem;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 1px;
		color: $gray-500;
	}
}
