body {
	background: $gray-200;
}

.la + span,
span + .la {
	margin-left: 0.5rem;
}

nav {
	a + a {
		margin-left: 15px;
	}
}

.clickable {
	cursor: pointer;
}

.heading {
	user-select: none;
	position: relative;
	padding: 17px 15px;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 15px;
	h1 {
		font-size: 1rem;
		margin: 0;
		text-align: right;
	}
	.buttons {
		list-style-type: none;
		position: absolute;
		left: 15px;
		top: 12px;
		padding: 0;
		margin: 0;
		& > li {
			display: inline-block;
			& + li {
				margin-left: 15px;
			}
		}
		.btn {
			font-weight: 700;
			line-height: 1.25;
			padding: 0.375rem;
			span {
				vertical-align: text-bottom;
			}
		}
	}
}

.hide {
	display: none;
	visibility: hidden;
}

.show {
	visibility: visible;
}

.transition {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.no-wrap {
	white-space: nowrap;
}

.text {
	&-bold {
		font-weight: 700 !important;
	}
	&-bolder {
		font-weight: 900 !important;
	}
	&-uppercase {
		text-transform: uppercase !important;
	}
}
