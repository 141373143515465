$font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont,
	"Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
	"Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-second: "Montserrat", sans-serif;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #38c172 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$primary: #321640 !default;

@import "./_sass/bootstrap";
@import "./_sass/general";
@import "./_sass/customize";
@import "./_sass/objects";
@import "./_sass/responsive";
